import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';

import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import loader from '../../assets/img/loader.svg';
import uploadIcon from '../../assets/img/upload.svg';
import { now } from 'jquery';
import { useStyles } from 'components/TemplateModal/TemplateModal';
import { TextField } from '@mui/material';
import moment from 'moment';
import { utcToLocal } from 'utility';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

//const useStyles = makeStyles(styles);

export default class Uploadfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [{}],
      refundsdata: [{}],
      template_delete_count: [{}],
      facilities: [{}],
      facility_id: '',
      loaded: false,
      Facility_folder: '',
      file: null,
      fileslist: [{}],
      uploadfiletext: 'Browse for file',
      uploadfilestatus: false,
      uploadSuccessMessage: false,
      uploadErrorMessage: false,
      csvStructure: null,
    };
  }
  // useEffect(()=>{
  //   setTimeout(() => {setErrormsg(null); setMsg(null)}, 2000);
  // },[msg,errormsg])

  uploadFile = async () => {
    try {
      var fileName = this.state.file && this.state.file.name;
      var fileExtension = fileName.split('.').pop();
      if (this.state.file == null) {
        alert('Please upload file');
        this.setState({
          uploadfiletext: 'Browse for file',
          uploadfilestatus: false,
        });
        return false;
      } else if (fileExtension != 'csv') {
        alert('Please upload csv file only');
        this.setState({
          uploadfiletext: 'Browse for file',
          uploadfilestatus: false,
        });
        return false;
      } else {
        this.setState({ loaded: true });
        //console.log("foldernm", this.state.user && this.state.user.name.toLowerCase().replace(/ /g, ''))
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append(
          'folder',
          this.state.user &&
            this.state.user.name.toLowerCase().replace(/ /g, '')
        );
        formData.append('user_id', this.state.user && this.state.user.id);

        await axios.post(API_BASE_URL + '/upload_file', formData);

        this.setState({
          file: null,
          uploadfilestatus: false,
          loaded: false,
        });

        const uploadefiles = await axios.get(
          API_BASE_URL + '/refund_file_list'
        );

        this.setState({
          fileslist: uploadefiles && uploadefiles.data.refundFiles,
          uploadSuccessMessage: true,
          uploadfiletext: 'Browse for file',
          loaded: false,
        });
      }
    } catch (error) {
      this.setState({ loaded: false });
      console.log(error);
    }
  };

  handleUploadFile = (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({
        file: event.target.files[0],
        uploadfiletext: 'Upload file',
        uploadfilestatus: true,
      });
    } else {
      alert('please select a file');
    }
    console.log(this.state.uploadfilestatus);
  };

  download = () => {
    window.location.assign('/sample.csv');
  };

  componentDidMount() {
    this.setState({ loaded: true });
    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
      this.setState({
        isLoggedIn: AppState.isLoggedIn,
        user: AppState.user,
        email: AppState.user && AppState.user.email,
        name: AppState.user && AppState.user.name,
      });
    }

    let userId = AppState && AppState.user && AppState.user.id;
    if (!userId) return (window.location.href = '/user/facilitylogin');
    let token = AppState && AppState.user && AppState.user.accessToken;
    if (AppState && AppState.user && AppState.user.admin_type == 'admin') {
      window.location.href = '/client/error';
    }
    this.setState({
      Facility_folder:
        this.state.user && this.state.user.name.toLowerCase().replace(/ /g, ''),
    });
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(API_BASE_URL + '/refund_file_list')
      .then((response) => {
        return response;
      })
      .then((uploadefiles) => {
        console.log(
          'uploadefilesuploadefilesuploadefiles',
          uploadefiles && uploadefiles.data
        );
        this.setState({
          fileslist: uploadefiles && uploadefiles.data.refundFiles,
          csvStructure: uploadefiles.data.csvFileStructure,
          loaded: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loaded: false });
      });
  }

  // const classes = useStyles();

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className='subHeading'>Upload Refund File</h2>
          <Card className='dashboardCard table_card_height'>
            <CardBody>
              <div>
                {this.state.loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}
                {this.state.uploadSuccessMessage && (
                  <div className='uploadTxt'>
                    <p>
                      File Uploaded SuccessFully.It can take up to 30mins to
                      process your file.
                    </p>{' '}
                  </div>
                )}
                {this.state.uploadErrorMessage && (
                  <div className='uploadTxt'>
                    <p style={{ color: 'red' }}>
                      You have already uploaded this file.
                    </p>{' '}
                  </div>
                )}
                <div className='t-header uploadCard'>
                  <div className='t-header-left'>
                    <input
                      type='file'
                      onChange={this.handleUploadFile}
                      value=''
                      id='logoimg'
                    />
                    <img src={uploadIcon} />
                    <p>{this.state.uploadfiletext}</p>
                  </div>
                </div>
                <div className='uploadTxt'>
                  {this.state.uploadfilestatus && (
                    <button className='btn' onClick={this.uploadFile}>
                      Upload
                    </button>
                  )}
                  <p>
                    <a href={API_BASE_URL + '/getSampleRefundFile'}>
                      {' '}
                      Download Sample Template{' '}
                    </a>{' '}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card className='dashboardCard table_card_height'>
            <CardBody>
              <table width='100%' id='template' className='paymentTable'>
                <thead>
                  <tr>
                    <th width='15%'>Uploaded Date</th>
                    <th width='15%'>File Name</th>
                    <th width='15%'>File Status</th>
                    <th width='15%'>Total Amount</th>
                    <th width='15%'>Record Count</th>
                    <th width='15%'>Processed Date</th>
                  </tr>
                </thead>
                {this.state.fileslist && this.state.fileslist.length > 0 ? (
                  <tbody>
                    {this.state.fileslist.map((obj, index) => (
                      <tr className='txtalign'>
                        <td width='15%'>
                          {utcToLocal(obj.created_at, 'MM/DD/YYYY')}
                        </td>
                        <td width='15%'>{obj && obj.filename}</td>
                        <td width='15%'>
                          {obj && obj.process_status == 0
                            ? 'Processing'
                            : 'Processed'}
                        </td>
                        <td width='15%'>
                          {obj.total_amount === null ? 'N/A' : obj.total_amount}
                        </td>
                        <td width='15%'>
                          {obj.total_records === null
                            ? 'N/A'
                            : obj.total_records}
                        </td>
                        <td width='15%'>
                          {obj && obj.process_status == 0
                            ? 'NA'
                            : obj && obj.upload_date}{' '}
                          {obj.processed_at ? (
                            <Moment format='MM-DD-YYYY HH:mm'>
                              {obj.processed_at}
                            </Moment>
                          ) : (
                            '-'
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>No File Uploaded</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
