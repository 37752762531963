import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';

import React, { useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { TextField } from '@mui/material';
import { useStyles } from 'components/TemplateModal/TemplateModal';
import { toast } from 'react-toastify';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
const labelStyle = {
  fontSize: '12px',
  color: 'grey',
  cursor: 'pointer',
};
const ManageDataMapping = () => {
  const classes = useStyles();
  const [csvMapping, setCsvMapping] = React.useState({});
  const [dbNames, setDbNames] = React.useState({});
  const updateFileStructure = async () => {
    try {
      const trimmedCsvMapping = {};
      for (const key of Object.keys(csvMapping)) {
        trimmedCsvMapping[key] = csvMapping[key].trim();
      }
      axios.post(API_BASE_URL + '/updateFileStructure', {
        structure: trimmedCsvMapping,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const onInputChange = (e) => {
    setCsvMapping((fs) => {
      return { ...fs, [e.target.name]: e.target.value };
    });
  };

  const getFileStructure = async () => {
    try {
      const response = await axios.get(API_BASE_URL + '/getFileStructure');
      setCsvMapping(response.data.csvFileMapping);
      // setDbNames(response.data.dbNames);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFileStructure();
  }, []);
  const inputList = [];
  if (csvMapping) {
    for (const key of Object.keys(csvMapping)) {
      inputList.push(
        <GridItem key={key} style={{ margin: '10px', minWidth: '30%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}>
            <div
              onClick={() => {
                navigator.clipboard.writeText(`{{${key}}}`);
                toast.success('Copied to clipboard', { autoClose: 1000 });
              }}
              style={{ display: 'flex', alignItems: 'center' }}>
              <label style={labelStyle}>
                {' '}
                {key} - {'{{'}
                {key}
                {'}}'}
              </label>{' '}
              <AssignmentOutlinedIcon
                style={{ color: '#3AB795', fontSize: '20px', cursor: 'copy' }}
              />
            </div>

            <TextField
              fullWidth={true}
              key={key}
              variant='outlined'
              color='primary'
              className={classes.root}
              placeholder={key}
              multiline
              inputProps={{
                style: {
                  outlineColor: '#00bcd4',
                  borderColor: '#00bcd4',
                  fontSize: '14px',
                  lineHeight: '1',
                  width: '300px',
                },
              }}
              minRows={1}
              maxRows={1}
              value={csvMapping[key]}
              onChange={onInputChange}
              name={key}
            />
          </div>
        </GridItem>
      );
    }
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h1 className='subHeading'>Create your mapping</h1>
        <Card>
          <CardBody>
            <h2 style={{ fontSize: '17px' }} className='page-heading'>
              In the email / letter templates, please use the values in "
              {'{{}}'}" including the double curly brackets to populate fields:
            </h2>
            <GridContainer style={{ justifyContent: 'space-between' }}>
              {inputList}
            </GridContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ margin: '10px' }}
                color='primary'
                onClick={updateFileStructure}>
                Save Mapping
              </Button>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ManageDataMapping;
