/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createBrowserHistory } from 'history';
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
// core components
import Admin from 'layouts/Admin.js';
import Client from 'layouts/Client.js';
import Facility from 'layouts/Facility.js';
import RTL from 'layouts/RTL.js';
import User from 'layouts/User.js';

import axios from 'axios';
import { handleLogout } from 'components/Navbars/AdminNavbarLinks.js';
import NeedHelp from 'components/NeedHelp/NeedHelp.jsx';
import { POSTHOG_KEY } from 'config.js';
import { POSTHOG_HOST } from 'config.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Claim from 'views/Clients/Claim.js';
import './assets/css/material-dashboard-react.css?v=1.9.0';
import './assets/scss/global.scss';

axios.defaults.withCredentials = true;
const hist = createBrowserHistory();

axios.interceptors.response.use(
  function (response) {
    toast.success(response.data.message ?? response.data.msg, {
      position: 'bottom-right',
      autoClose: 5000,
    });
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response, 'res');
    return response;
  },
  function (error) {
    let state = localStorage['appState'];
    toast.error(error.response.data.message ?? error.response?.data.msg, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // do not redirect if user is on user activation page
    if (window.location.pathname.includes('user/activation')) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      // console.log(error.response);
      if (!state) {
        window.location.href = `/user/${
          error.response.data.redirect === 'admin' ? 'login' : 'facilitylogin'
        }`;
        return Promise.reject(error);
      }
      let AppState = JSON.parse(state);
      window.location.href = `/user/${
        AppState?.user.admin_type === 'admin' ? 'login' : 'facilitylogin'
      }`;
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

// Obsolete code
// axios.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     let state = localStorage["appState"]
//     let AppState
//     if (state) {
//       AppState = JSON.parse(state)
//     }

//     let accessToken = AppState?.user?.accessToken
//     let refreshToken = AppState?.user?.refreshToken

//     config.headers = {
//       Authorization: `Bearer ${accessToken} ${refreshToken}`,
//     }
//     return config
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error)
//   }
// )

let pageLoaded = false;
const checkAndHandleLogout = () => {
  let lastActivity = localStorage.getItem('lastActivity');
  checkLastActivityAndHandleLogout(lastActivity);
};

const checkLastActivityAndHandleLogout = (lastActivity) => {
  // // skip checking for user activation
  if (window.location.pathname.includes('user/activation')) {
    return;
  }

  if (lastActivity && Date.now() - lastActivity > 900000) {
    localStorage.removeItem('lastActivity');

    console.log('logout from user inactivity');
    handleLogout();
  }
};

window.addEventListener('pageshow', () => {
  // when the user reopens the tab from sleep, check if the token has expired
  const appState =
    localStorage.getItem('appState') &&
    JSON.parse(localStorage.getItem('appState'));

  setTimeout(() => {
    pageLoaded = true;
  }, 3000);

  // if the user token has expired, logout the user
  if (appState?.isLoggedIn) {
    const lastActivity = localStorage.getItem('lastActivity');
    checkLastActivityAndHandleLogout(lastActivity);
  }
});

window.addEventListener('mousemove', () => {
  // set the last activity time in local storage only if the user is logged in
  // activate only after 3 sec the page has loaded to verify token expiration on reopen tab/browser
  if (pageLoaded) {
    const appState =
      localStorage.getItem('appState') &&
      JSON.parse(localStorage.getItem('appState'));

    if (appState?.isLoggedIn) {
      localStorage.setItem('lastActivity', Date.now());
    }
  }
});

// every 5 sec check if user is inactive for 15 min and logout
setInterval(checkAndHandleLogout, 5000);

ReactDOM.render(
  <>
    <PostHogProvider
      apiKey={POSTHOG_KEY}
      options={{ api_host: POSTHOG_HOST }}></PostHogProvider>

    <Router history={hist}>
      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Switch>
        <Route path='/facility' component={Facility} />
        <Route path='/admin' component={Admin} />
        <Route path='/client' component={Client} />
        <Route path='/user' component={User} />
        <Route path='/rtl' component={RTL} />
        <Route path='/claim' component={Claim} />
        <Redirect from='/' to='/claim' />
      </Switch>
    </Router>
    <NeedHelp />
  </>,
  document.getElementById('root')
);
