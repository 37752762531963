/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// core components/views for Admin layout
import Facilitydashboard from 'views/Dashboard/Facilitydashboard.js';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AssessmentIcon from '@material-ui/icons/Assessment';

import Payments from 'views/Managefile/Payments.js';
import Uploadfile from 'views/Managefile/Uploadfile.js';
// core components/views for RTL layout
import EmailTemplate from 'views/Managefile/EmailTemplate';
import {
  AccountTree,
  BusinessCenter,
  EmailRounded,
  EventBusyRounded,
  GavelRounded,
  MonetizationOnRounded,
} from '@material-ui/icons';
import ManageDataMapping from 'views/Managefile/ManageDataMapping';
import Econsent from 'views/Facilities/Econsent';
import UnclaimedRefunds from 'views/Dashboard/UnclaimedRefunds';
import FacilityTransaction from 'views/Clients/FacilityTransaction';
import CheckPermissionsComponent from 'views/Facilities/CheckPermissions';
import CreateOrEditBusinessUnit from 'components/CreateOrEditBusinessUnit/CreateOrEditBusinessUnit';
import BusinessUnits from 'views/Facilities/BusinessUnits';
import { BUSINESS_UNITS_FEATURE_FLAG } from 'utility';
import DataMappings from 'views/Managefile/DataMappings';

const dashboardRoutes = [
  {
    path: '/facilitydashboard',
    name: 'Dashboard',
    rtlName: 'قائمة الجدول',
    icon: AssessmentIcon,
    component: Facilitydashboard,
    layout: '/facility',
  },
  {
    path: '/create-business-unit',
    name: 'Dashboard',
    rtlName: 'قائمة الجدول',
    component: () => (
      <CheckPermissionsComponent parentKey='business_units'>
        <CreateOrEditBusinessUnit />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
    invisible: true,
  },
  {
    path: '/edit-business-unit/:id',
    name: 'Dashboard',
    rtlName: 'قائمة الجدول',
    component: () => (
      <CheckPermissionsComponent parentKey='business_units'>
        <CreateOrEditBusinessUnit />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
    invisible: true,
  },
  {
    path: '/business-units',
    name: 'Business Units',
    rtlName: 'قائمة الجدول',
    icon: BusinessCenter,
    component: () => (
      <CheckPermissionsComponent parentKey='business_units'>
        <BusinessUnits />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
    featureFlag: BUSINESS_UNITS_FEATURE_FLAG,
  },
  {
    path: '/transaction',
    name: 'Refund Reporting',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: CloudDownloadIcon,
    component: () => (
      <CheckPermissionsComponent parentKey='refund_reporting'>
        <Payments />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
  },
  {
    path: '/uploadrefundfile',
    name: 'Upload Refund File',
    rtlName: 'قائمة الجدول',
    icon: CloudUploadIcon,
    component: () => (
      <CheckPermissionsComponent parentKey='refundee_file'>
        <Uploadfile />
      </CheckPermissionsComponent>
    ),

    layout: '/facility',
  },
  {
    path: '/templates',
    name: 'Templates',
    rtlName: 'قائمة الجدول',
    icon: EmailRounded,
    component: () => (
      <CheckPermissionsComponent parentKey='email_template'>
        <EmailTemplate />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
  },
  {
    path: '/manageDataMapping',
    name: 'Manage Data Mapping',
    rtlName: 'قائمة الجدول',
    icon: AccountTree,
    component: () => (
      <CheckPermissionsComponent parentKey='data_mapping'>
        <DataMappings />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
  },
  {
    path: '/unclaimed-refunds',
    name: 'Unclaimed Refunds',
    rtlName: 'قائمة الجدول',
    icon: EventBusyRounded,
    component: () => (
      <CheckPermissionsComponent parentKey='unclaimed_refunds'>
        <UnclaimedRefunds />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
  },
  {
    path: '/facility-transaction',
    name: 'Transactions',
    rtlName: 'قائمة الجدول',
    icon: MonetizationOnRounded,
    component: () => (
      <CheckPermissionsComponent parentKey='transaction_page'>
        <FacilityTransaction />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
  },
  {
    path: '/manageEconsent',
    name: 'E-Consent',
    rtlName: 'قائمة الجدول',
    icon: GavelRounded,
    component: () => (
      <CheckPermissionsComponent parentKey='e_consent'>
        <Econsent />
      </CheckPermissionsComponent>
    ),
    layout: '/facility',
  },

  /* {
     path: "/typography",
     name: "Typography",
     rtlName: "طباعة",
     icon: LibraryBooks,
     component: Typography,
     layout: "/admin"
   },
   {
     path: "/icons",
     name: "Icons",
     rtlName: "الرموز",
     icon: BubbleChart,
     component: Icons,
     layout: "/admin"
   },
   {
     path: "/maps",
     name: "Maps",
     rtlName: "خرائط",
     icon: LocationOn,
     component: Maps,
     layout: "/admin"
   },
   {
     path: "/notifications",
     name: "Notifications",
     rtlName: "إخطارات",
     icon: Notifications,
     component: NotificationsPage,
     layout: "/admin"
   },
   {
     path: "/rtl-page",
     name: "RTL Support",
     rtlName: "پشتیبانی از راست به چپ",
     icon: Language,
     component: RTLPage,
     layout: "/rtl"
   }*/
];

export default dashboardRoutes;
