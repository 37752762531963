import { usePostHog } from 'posthog-js/react';
import ManageDataMapping from './ManageDataMapping';
import { NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG } from 'utility';

export default function DataMappings() {
  const posthog = usePostHog();
  if (posthog.isFeatureEnabled(NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG)) {
    console.log('Feature is enabled');
  }
  return (
    <div>
      {!posthog.isFeatureEnabled(NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG) ? (
        <ManageDataMapping />
      ) : (
        <div>New work flow</div>
      )}
    </div>
  );
}
