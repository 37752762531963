import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';
import DatePicker from 'react-datepicker';
import { API_BASE_URL, API_BASE_IMG_URL, CLIENT_URL } from '../../config';
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
// import Table from "components/Table/Table.js";
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import loader from '../../assets/img/loader.svg';

// components for table.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import Button from '../../components/CustomButtons/Button';
// react-CSV downloader
import { CSVLink } from 'react-csv';
import { Checkbox } from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  EditRounded,
  LinkRounded,
} from '@material-ui/icons';
import { Base64 } from 'js-base64';
import { toast } from 'react-toastify';
import { Box, Modal } from '@material-ui/core';
import { modalStyle } from 'views/Clients/Claim';
import CustomInput from 'components/CustomInput/CustomInput';
import moment from 'moment';
import { utcToLocal } from 'utility';
import { usePostHog } from 'posthog-js/react';
import { getBusinessUnits } from 'views/Facilities/BusinessUnits';
import { BUSINESS_UNITS_FEATURE_FLAG } from 'utility';

//const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function UnclaimedRefunds() {
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sortBy, setSortBy] = useState({ name: 'created_at', order: -1 });
  const [payments, setPayments] = useState([]);
  const [sortedPayments, setSortedPayments] = useState([]);
  const [updateEmailOfRefund, setUpdateEmailOfRefund] = useState(null);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [modalErr, setModalErr] = useState('');
  const posthog = usePostHog();

  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
  useEffect(async () => {
    setBusinessUnits(await getBusinessUnits());
  }, []);

  const getRefunds = async () => {
    try {
      setLoaded(true);

      const formData = new FormData();
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('business_unit_id', selectedBusinessUnit);
      setPayments(
        (
          await axios.post(API_BASE_URL + '/unclaimed-refunds', formData)
        ).data.map((payment) => ({
          ...payment,
          refund_amount: +payment.refund_amount,
        }))
      );
      setLoaded(false);
    } catch (error) {
      setLoaded(false);

      console.log(error);
    }
  };

  /*** Change start date */
  const changeDate = (event) => {
    setStartDate(event ?? '');
  };

  /*** Change end date */
  const changeEndDate = (event) => {
    setEndDate(event ?? '');
  };

  useEffect(() => {
    getRefunds();
  }, [startDate, endDate, selectedBusinessUnit]);

  // functions for pagination.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, payments.length - page * rowsPerPage);

  useEffect(() => {
    console.log(sortBy.order, sortBy.name);
    const array = [...payments];

    const sortedArray = array.sort((payment1, payment2) => {
      if (payment1[sortBy.name] < payment2[sortBy.name]) {
        return -1;
      }
      if (payment1[sortBy.name] > payment2[sortBy.name]) {
        return 1;
      }
      return 0;
    });
    setSortedPayments(
      sortBy.order === 1
        ? sortedArray
        : sortBy.order === -1
        ? sortedArray.reverse()
        : [...payments]
    );
  }, [payments, sortBy.order, sortBy.name]);

  const sortByColumn = (columnName) => {
    setSortBy({
      name: columnName,
      order:
        sortBy.name === columnName
          ? sortBy.order === 1
            ? -1
            : sortBy.order === -1
            ? 1
            : sortBy.order === 0
            ? 1
            : 1
          : 1,
    });
  };

  const sortArrow = (columnName) => {
    // console.log(sortBy.order);
    return columnName === sortBy.name ? (
      sortBy.order === 1 ? (
        <ArrowDownward fontSize='small' />
      ) : sortBy.order === -1 ? (
        <ArrowUpward fontSize='small' />
      ) : sortBy.order === 0 ? (
        ''
      ) : (
        ''
      )
    ) : (
      ''
    );
  };

  const copyLinkToClipBoard = (id) => {
    const encodedId = Base64.encode(id);
    navigator.clipboard.writeText(
      CLIENT_URL + '/client/clientdetail/' + encodedId
    );
    toast.success('Copied to clipboard', { autoClose: 1000 });
  };

  const updateEmail = (e, editEmail) => {
    e.preventDefault();

    if (!editEmail) {
      return setModalErr('No email provided');
    }
    const formData = new FormData();
    formData.append('email', updatedEmail.toLowerCase());
    formData.append('id', editEmail);
    formData.append('zipCode', localStorage.getItem('zipCode'));
    axios
      .post(API_BASE_URL + '/updaterefundEmail/' + editEmail, formData)
      .then((res) => {
        setUpdateEmailOfRefund(null);
        getRefunds();
        setUpdatedEmail('');
      })
      .catch((err) => {
        setModalErr(err.response?.data?.message);
      });
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='primary'>
            <h4>Unclaimed Refunds</h4>
          </CardHeader>

          <CardBody>
            <div>
              {loaded && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              <div className='t-header'>
                <div className='t-header-left'>
                  From:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={startDate}
                    onChange={changeDate}
                  />
                </div>
                <div className='t-header-left'>
                  To:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={endDate}
                    onChange={changeEndDate}
                  />
                </div>
                {posthog.isFeatureEnabled(BUSINESS_UNITS_FEATURE_FLAG) && (
                  <div className='t-header-left'>
                    <div>
                      <select
                        id='status'
                        className='full-width-elem'
                        onChange={(e) =>
                          setSelectedBusinessUnit(e.target.value)
                        }>
                        <option value={''}>Business Unit</option>
                        {businessUnits.map((obj) => (
                          <option value={obj.id} key={obj.id}>
                            {obj.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow style={{ cursor: 'pointer', userSelect: 'none' }}>
                    <TableCell
                      width='15%'
                      onClick={() => sortByColumn('email_sent_date')}>
                      Email Sent Date {sortArrow('email_sent_date')}
                    </TableCell>
                    <TableCell
                      width='30%'
                      onClick={() => sortByColumn('last_name')}>
                      Customer {sortArrow('last_name')}
                    </TableCell>
                    {posthog.isFeatureEnabled(BUSINESS_UNITS_FEATURE_FLAG) && (
                      <TableCell
                        onClick={() => sortByColumn('business_unit_name')}>
                        Business Unit {sortArrow('business_unit_name')}
                      </TableCell>
                    )}
                    <TableCell onClick={() => sortByColumn('refund_amount')}>
                      Amount($) {sortArrow('refund_amount')}
                    </TableCell>
                    <TableCell onClick={() => sortByColumn('email_sent')}>
                      No of Email Sent {sortArrow('email_sent')}
                    </TableCell>
                    <TableCell onClick={() => sortByColumn('refund_status')}>
                      Payment Status {sortArrow('refund_status')}
                    </TableCell>
                    <TableCell
                    // onClick={() => sortByColumn('refund_status')}
                    >
                      Refund Link
                    </TableCell>
                  </TableRow>
                </TableHead>
                {sortedPayments && sortedPayments.length > 0 ? (
                  <TableBody>
                    {(rowsPerPage > 0
                      ? sortedPayments.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : sortedPayments
                    ).map((obj, index) => (
                      <TableRow className='txtalign' key={index}>
                        <TableCell>
                          {obj.email_sent_date
                            ? utcToLocal(obj.email_sent_date, 'MM/DD/YYYY')
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {obj.first_name} {obj.last_name}
                          <p style={{ fontSize: '12px' }}>
                            {!obj.email ? (
                              'N/A'
                            ) : (
                              <span style={{ display: 'flex' }}>
                                <p style={{ fontSize: '12px' }}>{obj.email}</p>
                                <EditRounded
                                  style={{ cursor: 'pointer' }}
                                  fontSize='small'
                                  onClick={() => {
                                    setUpdateEmailOfRefund(obj);
                                  }}
                                />
                              </span>
                            )}
                          </p>
                        </TableCell>
                        {posthog.isFeatureEnabled(
                          BUSINESS_UNITS_FEATURE_FLAG
                        ) && <TableCell>{obj.business_unit_name}</TableCell>}
                        <TableCell>
                          {parseFloat(obj && obj.refund_amount).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                        <TableCell>{obj.email_sent}</TableCell>
                        <TableCell>{obj.refund_status ?? 'Pending'}</TableCell>
                        <TableCell onClick={() => copyLinkToClipBoard(obj.id)}>
                          <a>
                            <LinkRounded />
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No Transaction Data Found</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={2}
                      count={payments.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <Modal
        open={updateEmailOfRefund ? true : false}
        onClose={() => setUpdateEmailOfRefund(null)}>
        <form onSubmit={(e) => updateEmail(e, updateEmailOfRefund?.id)}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Enter Your Email</h4>
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              {modalErr && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  {modalErr}
                </p>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    labelText='Email'
                    value={updateEmailOfRefund?.email}
                    autoComplete='off'
                    disabled
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    labelText='New Email'
                    onChange={(e) => setUpdatedEmail(e.target.value)}
                    value={updatedEmail}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <div>
                <Button variant='contained' color='primary' type='submit'>
                  Update
                </Button>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>
    </GridContainer>
  );
}
