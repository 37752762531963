import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';
import DatePicker from 'react-datepicker';
import { API_BASE_URL, API_BASE_IMG_URL, CLIENT_URL } from '../../config';
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from 'components/Grid/GridContainer.js';
// import Table from "components/Table/Table.js";
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import loader from '../../assets/img/loader.svg';

// components for table.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import Button from '../../components/CustomButtons/Button';
import SearchIcon from '@material-ui/icons/Search';

// react-CSV downloader
import { CSVLink } from 'react-csv';
import { Checkbox, Dialog, Modal, TextField } from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  EditRounded,
  HistoryRounded,
} from '@material-ui/icons';
import { Base64 } from 'js-base64';
import { toast } from 'react-toastify';
import { Box } from '@material-ui/core';
import { modalStyle } from 'views/Clients/Claim';
import CustomInput from 'components/CustomInput/CustomInput';
import { useStyles } from 'components/TemplateModal/TemplateModal';
import { labelStyle } from 'views/Managefile/EmailTemplate';
import moment from 'moment';
import { checkPermission } from 'views/Facilities/CheckPermissions';
import { utcToLocal } from 'utility';
import { getBusinessUnits } from 'views/Facilities/BusinessUnits';
import { usePostHog } from 'posthog-js/react';
import { BUSINESS_UNITS_FEATURE_FLAG } from 'utility';
import LongMenu from 'components/LongMenu/LongMenu';
import { debounce } from 'lodash';
import { getFacilities } from 'views/Facilities/Facilities';

export const useStylesDanger = makeStyles({
  root: {
    // input label when focused
    '& label.Mui-focused': {
      color: 'red',
    },

    // focused color for input with variant='standard'
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    // focused color for input with variant='filled'
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },

    '& .MuiOutlinedInput-root .danger': {
      borderColor: 'red',
    },
    // focused color for input with variant='outlined'
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const statusOptions = [
  'Completed',
  'Pending-sent',
  'Escheated',
  'Document-sent',
  'Pending',
  'Not-initiated',
  'ACH-sent',
  'ACH-returned',
  'Pending-pp-sent',
  'Voided',
];

const checkStatusOptions = ['Pending', 'Sent-uncashed', 'Voided'];

const achStatusOptions = ['Pending', 'ACH-sent', 'ACH-returned'];

const giftCardStatusOptions = ['Pending'];

const donationStatusOptions = ['Pending'];

const statusChangeToOptions = [
  // 'Completed',
  // 'Pending-sent',
  // 'Escheated',
  // 'Document-sent',
  // 'Pending',
  // 'ACH-sent',
  // 'Pending-pp-sent',
  'Uninitiated',
  'ACH-returned',
  // 'ACH-returned (Uninitiated)',
  // 'Voided',
];

export const REFUND_STATUSES = Object.freeze({
  UNCLAIMED: 'Unclaimed',
  REQUESTED: 'Requested',
  COMPLETED: 'Completed',
  ESCHEATED: 'Escheated',
  EXCEPTION: 'Exception',
  CANCELED: 'Canceled',
});

const paymentMethods = [
  { text: 'ACH', value: 7 },
  { text: 'Donation', value: 6 },
  { text: 'Check', value: 5 },
  { text: 'Gift Card', value: 4 },
];

const getRefundMethodString = (refundMethod) => {
  return paymentMethods.find((method) => method.value === refundMethod)?.text;
};

export const COMMUNICATION_EXCEPTIONS = Object.freeze({
  INVALID_ADDRESS: 'Invalid Address',
  UNDELIVERABLE: 'Undeliverable',
});

const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: false,
  timeZone: 'America/New_York',
};
export default function FacilityTransaction({ role }) {
  const [payments, setPayments] = useState([]);
  const [sortBy, setSortBy] = useState({ name: 'created_at', order: -1 });
  const [sortedPayments, setSortedPayments] = useState([]);
  const [loading, setLoding] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [facility, setFacility] = useState('');
  const [changeStatusTo, setChangeStatusTo] = useState('');
  const [selectedRefund, setSelectedRefund] = useState(null);
  const [currentInfo, setCurrentInfo] = useState(null);
  const [originalInfo, setOriginalInfo] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const [modalErr, setModalErr] = useState('');
  const [search, setSearch] = useState('');
  const [currentCheckRefund, setCurrentCheckRefund] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedForTrackChangesId, setSelectedForTrackChangesId] = useState(
    null
  );
  const [subStatus, setSubStatus] = useState('');
  const [dateFilterType, setDateFilterType] = useState('created_at');
  const posthog = usePostHog();
  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
  const [transactionStatuses, setTransactionStatuses] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [filteredBusinessUnits, setFilteredBusinessUnits] = useState([]);
  useEffect(async () => {
    const businessUnitsData = (
      await axios.get(
        API_BASE_URL + `/business-units${role === 'admin' ? '/admin/' : ''}`
      )
    ).data;
    setBusinessUnits(businessUnitsData);
    setFilteredBusinessUnits(businessUnitsData);

    if (role === 'admin') {
      const facilities = (await getFacilities({ facility: true })).data;
      console.log(facilities);
      setFacilities(facilities);
    }
  }, []);

  const [infoHistory, setInfoHistory] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const classes = useStyles();
  const [quickFilter, setQuickFilter] = useState('');
  const dangerClass = useStylesDanger();
  const getRefunds = async (clear, limit, searchValue) => {
    try {
      setLoding(true);
      console.log(status);

      const refunds = await axios.post(
        API_BASE_URL +
          `/refundlist_paginated${role === 'admin' ? '_admin' : ''}`,
        {
          limit: limit ?? 25,
          offset: clear ? 0 : offSet,
          search: searchValue ?? '',
          refundMethod: paymentMethod || undefined,
          facility_id: facility,
          refundStatus: status || undefined,
          orderByColumn: sortBy.name,
          orderType: sortBy.order == 1 ? 'ASC' : 'DESC',
          startDate,
          endDate,
          business_unit_id: selectedBusinessUnit,
          transactionStatus,
          subStatus,
          dateFilterType,
          additionalInfo,
          isError: quickFilter === 'Flagged Records',
        }
      );
      setLoding(false);
      const payments = refunds.data.refunds.map((payment) => {
        payment.isChecked = false;
        payment.refund_amount = +payment.refund_amount;
        payment.refund_method_string =
          payment.refund_method == 2
            ? 'Paypal'
            : payment.refund_method == 3
            ? 'Venmo'
            : payment.refund_method == 4
            ? 'Gift Card'
            : payment.refund_method == 5
            ? 'Check'
            : payment.refund_method == 6
            ? 'Donations'
            : payment.refund_method == 7
            ? 'ACH'
            : payment.refund_method == 0
            ? 'Escheated'
            : '';
        payment.refund_status = payment.refund_status;
        return payment;
      });
      if (clear) {
        return setPayments([...payments]);
      }

      setPayments((prev) => [...prev, ...payments]);
    } catch (err) {
      console.log(err);
    }
  };

  const handler = useCallback(
    debounce((searchValue) => {
      getRefunds(true, undefined, searchValue);
      setOffSet(25);
      console.log('searching', searchValue);
    }, 1000),
    []
  );

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    handler(event.target.value);
  };

  const selectstatus = (event) => {
    setTransactionStatus('');
    setSubStatus('');
    setStatus(event.target.value);
    setPaymentMethod('');
  };
  /*** Change start date */
  const changeDate = (event) => {
    setStartDate(event ?? '');
  };

  /*** Change end date */
  const changeEndDate = (event) => {
    setEndDate(event ?? '');
  };

  useEffect(() => {
    getRefunds(true);
    setOffSet(25);
  }, [
    startDate,
    endDate,
    status,
    sortBy.name,
    sortBy.order,
    facility,
    paymentMethod,
    selectedBusinessUnit,
    transactionStatus,
    subStatus,
    dateFilterType,
    additionalInfo,
  ]);

  const onCheck = (id) => {
    const newPayments = payments.map((payment) => {
      if (payment.id === id) {
        payment.isChecked = !payment.isChecked;
      }
      return payment;
    });
    setPayments(newPayments);
  };
  const onAllCheck = (e) => {
    console.log(page + 1, rowsPerPage);
    const newPayments = payments.map((payment, index) => {
      if (rowsPerPage < 0 && !checkIfRefundCanBeUpdated(payment)) {
        payment.isChecked = e.target.checked;
        return payment;
      }
      if (
        (page + 1) * rowsPerPage > index &&
        !checkIfRefundCanBeUpdated(payment)
      )
        payment.isChecked = e.target.checked;
      return payment;
    });

    setPayments(newPayments);
  };

  const sortByColumn = (columnName) => {
    setSortBy({
      name: columnName,
      order:
        sortBy.name === columnName
          ? sortBy.order === 1
            ? -1
            : sortBy.order === -1
            ? 1
            : sortBy.order === 0
            ? 1
            : 1
          : 1,
    });
  };

  const sortArrow = (columnName) => {
    // console.log(sortBy.order);
    return columnName === sortBy.name ? (
      sortBy.order === 1 ? (
        <ArrowDownward fontSize='small' />
      ) : sortBy.order === -1 ? (
        <ArrowUpward fontSize='small' />
      ) : sortBy.order === 0 ? (
        ''
      ) : (
        ''
      )
    ) : (
      ''
    );
  };

  const onQuickFilterChange = (e) => {
    if (!e.target.value) {
      setQuickFilter('');
      setStatus('');
      setTransactionStatus('');

      return;
    }
    setQuickFilter(e.target.value);

    setStatus(REFUND_STATUSES.REQUESTED);
    if (e.target.value === 'All Returned') {
      setTransactionStatus('ACH-returned');
      setTransactionStatuses(achStatusOptions);
      setPaymentMethod('7');
    } else if (e.target.value === 'All Voided') {
      setTransactionStatus('Voided');
      setTransactionStatuses(checkStatusOptions);
      setPaymentMethod('5');
    }
  };

  useEffect(async () => {
    if (!selectedRefund) {
      setStatusHistory([]);
      return;
    }

    setStatusHistory(
      (
        await axios.get(
          API_BASE_URL +
            `/status_history${role === 'admin' ? '' : '_facility'}/` +
            selectedRefund
        )
      ).data
    );
  }, [selectedRefund]);

  const copyLinkToClipBoard = (id) => {
    const encodedId = Base64.encode(id);
    navigator.clipboard.writeText(
      CLIENT_URL + '/client/clientdetail/' + encodedId
    );
    toast.success('Copied to clipboard', { autoClose: 1000 });
  };
  const selectedPayments = payments.filter((payment) => payment.isChecked);

  const updateInfo = (e, currentInfo, originalInfo) => {
    e.preventDefault();

    const newInfo = {
      first_name: currentInfo.first_name?.trim(),
      last_name: currentInfo.last_name?.trim(),
      email: currentInfo.email?.trim(),
      refund_to_address_line1: currentInfo.refund_to_address_line1?.trim(),
      refund_to_address_line2: currentInfo.refund_to_address_line2?.trim(),
      refund_to_city: currentInfo.refund_to_city?.trim(),
      refund_to_state: currentInfo.refund_to_state?.trim(),
      refund_to_zip: currentInfo.refund_to_zip?.trim(),
    };

    const oldInfo = {
      first_name: originalInfo.first_name,
      last_name: originalInfo.last_name,
      email: originalInfo.email,
      refund_to_address_line1: originalInfo.refund_to_address_line1,
      refund_to_address_line2: originalInfo.refund_to_address_line2,
      refund_to_city: originalInfo.refund_to_city,
      refund_to_state: originalInfo.refund_to_state,
      refund_to_zip: originalInfo.refund_to_zip,
    };

    //check if any info has changed
    let changed = false;
    for (let key in newInfo) {
      if (newInfo[key] !== oldInfo[key]) {
        changed = true;
        break;
      }
    }
    if (changed && !currentInfo.comment) {
      toast.error('Please enter a comment', { autoClose: 2000 });
      return;
    }

    axios
      .post(
        API_BASE_URL +
          `/update_refund_info${role === 'admin' ? '_admin' : ''}/` +
          currentInfo.id,
        {
          oldInfo,
          newInfo,
          comment: currentInfo.comment,
          resendEmail: currentInfo.resendEmail,
          id: currentInfo.id,
          changed,
        }
      )
      .then((res) => {
        if (currentCheckRefund)
          setCurrentCheckRefund({
            ...currentCheckRefund,
            ...newInfo,
          });
        setCurrentInfo(null);
        setOriginalInfo(null);
        getRefunds(true, payments.length);
      })
      .catch((err) => {
        setModalErr(err.response?.data?.message);
      });
  };

  const onInputChange = (e) => {
    if (e.target.type === 'text' || e.target.type === 'email') {
      setCurrentInfo((currentInfo) => {
        const info = { ...currentInfo };
        info[e.target.name] = e.target.value;
        return info;
      });
    }
    if (e.target.type === 'checkbox') {
      setCurrentInfo((currentInfo) => {
        const info = { ...currentInfo };
        info[e.target.name] = e.target.checked;
        return info;
      });
    }
  };
  const processPayment = (id) => {
    const encodedId = Base64.encode(id);
    axios
      .post(API_BASE_URL + '/add_check_facility/' + encodedId, {})
      .then((response) => {
        setCurrentCheckRefund(null);
        getRefunds(true);
      });
  };

  const checkIfRefundCanBeUpdated = (refund) => {
    return !(
      changeStatusTo &&
      refund.refund_method == 7 &&
      ((refund.refund_status === 'Completed' &&
        changeStatusTo === 'ACH-returned') ||
        (refund.refund_status === 'Completed' &&
          changeStatusTo === 'ACH-returned (Uninitiated)') ||
        (refund.refund_status === 'ACH-returned' &&
          changeStatusTo === 'Uninitiated'))
    );
  };
  const getInfoHistory = async (id, refund) => {
    try {
      const result = await axios.get(
        API_BASE_URL +
          `/get_refund_info_history${role === 'admin' ? '_admin' : ''}/` +
          id
      );
      const infoHistoryArray = [];

      result.data.forEach((info, index) => {
        infoHistoryArray.push({
          first_name: info.new_info.first_name,
          last_name: info.new_info.last_name,
          email: info.new_info.email,
          address: `${info.new_info.refund_to_address_line1},
${info.new_info.refund_to_address_line2},
${info.new_info.refund_to_city},
${info.new_info.refund_to_state}, ${info.new_info.refund_to_zip}.`,
          comment: info.comment,
          updated_by: `${info.updated_by} on ${moment(info.created_at).format(
            'YYYY-MM-DD HH:mm:ss'
          )}`,
        });
        if (index === result.data.length - 1) {
          infoHistoryArray.push({
            first_name: info.old_info.first_name,
            last_name: info.old_info.last_name,
            email: info.old_info.email,
            address: `${info.old_info.refund_to_address_line1},
${info.old_info.refund_to_address_line2},
${info.old_info.refund_to_city},
${info.old_info.refund_to_state}, ${info.old_info.refund_to_zip}.`,
            comment: 'N/A',
            updated_by: 'N/A',
          });
        }
      });

      if (!infoHistoryArray.length) {
        infoHistoryArray.push({
          first_name: refund.first_name,
          last_name: refund.last_name,
          email: refund.email,
          address: `${refund.refund_to_address_line1},
${refund.refund_to_address_line2},
${refund.refund_to_city},
${refund.refund_to_state}, ${refund.refund_to_zip}.`,
          comment: 'N/A',
          updated_by: 'N/A',
        });
      }

      setInfoHistory(infoHistoryArray);
    } catch (err) {
      console.log(err);
    }
  };

  const getClass = (info, index, input) => {
    return info.first_name &&
      index !== infoHistory.length - 1 &&
      info[input] &&
      infoHistory[index + 1][input] &&
      info[input] !== infoHistory[index + 1][input]
      ? dangerClass.root
      : classes.root;
  };

  const onScrollEnd = async (e) => {
    console.log('scroll');
    if (
      e.target.scrollHeight - e.target.scrollTop - 1 <=
      e.target.clientHeight
    ) {
      console.log('end');
      setLoding(false);
      await getRefunds(false);
      setOffSet(offSet + 25);
    }
  };

  const downloadFile = async () => {
    try {
      const result = await axios.get(
        API_BASE_URL +
          `/downloadReport${role === 'admin' ? '_admin' : ''}?filters=` +
          JSON.stringify({
            search: search,
            refundMethod: paymentMethod || undefined,
            facility_id: facility,
            refundStatus: status || undefined,
            orderByColumn: sortBy.name,
            orderType: sortBy.order == 1 ? 'ASC' : 'DESC',
            startDate,
            endDate,
            business_unit_id: selectedBusinessUnit,
            additionalInfo,
            isError: quickFilter === 'Flagged Records',
            subStatus,
            dateFilterType,
            transactionStatus,
          })
      );
      fileDownload(result.data, 'RefundReport.csv');
    } catch (err) {
      console.log(err);
    }
  };

  const updatePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
    const transactionStatusesToUse =
      e.target.value == 7
        ? achStatusOptions
        : e.target.value == 6
        ? donationStatusOptions
        : e.target.value == 4
        ? giftCardStatusOptions
        : checkStatusOptions;
    setTransactionStatuses(transactionStatusesToUse);
  };

  const statusAction = async (path, id) => {
    const alert = confirm('Are you sure you want to change the status?');
    if (!alert) return;
    await axios.get(API_BASE_URL + path + id);
    getRefunds(true);
  };

  const onFacilitySelect = (e) => {
    setFacility(e.target.value);
    setFilteredBusinessUnits(
      businessUnits.filter(
        (businessUnit) => businessUnit.facility_id == e.target.value
      )
    );
  };

  const toggleDelete = async (refundId) => {
    await axios.get(API_BASE_URL + `/toggle_delete/` + refundId);

    getRefunds(true);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ marginTop: '-10px' }}>
          <CardHeader
            style={{ display: 'flex', justifyContent: 'space-between' }}
            color='primary'>
            <h4>Transaction Details </h4>
          </CardHeader>

          <CardBody>
            <div
              style={{ maxHeight: '75vh', overflow: 'auto' }}
              onScroll={onScrollEnd}>
              {loading && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}

              <div className='t-header'>
                <div className='t-header-left'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        id='status'
                        className='full-width-elem'
                        onChange={(e) => setDateFilterType(e.target.value)}>
                        <option value='created_at'>Date Processed</option>
                        <option value='status_updatedat'>Last updated</option>
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
                <div className='t-header-left'>
                  From:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={startDate}
                    onChange={changeDate}
                  />
                </div>
                <div className='t-header-left'>
                  To:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={endDate}
                    onChange={changeEndDate}
                  />
                </div>

                <div className='t-header-right' style={{ padding: '10px' }}>
                  <div className='custom-search-box'>
                    <input
                      type='search'
                      className='seacrh-field'
                      value={search}
                      onChange={onSearchChange}
                      placeholder='Name, Email, Check, Customer and CPD ID'
                    />
                    <span className='search-icon'>
                      <SearchIcon />
                    </span>
                  </div>
                </div>
              </div>
              <div className='t-header'>
                <div className='t-header-left'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        id='status'
                        className='full-width-elem'
                        value={status}
                        onChange={selectstatus}>
                        <option value=''>Refund Status</option>
                        {Object.values(REFUND_STATUSES).map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>

                {status === REFUND_STATUSES.UNCLAIMED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={subStatus}
                          onChange={(e) => setSubStatus(e.target.value)}>
                          <option value=''>SubStatus</option>
                          <option value='Email Sent'>Email Sent</option>
                          <option value='Communication Pending'>
                            Communication Pending
                          </option>
                          {/* <option value='Text Sent'>Text Sent</option> */}
                          <option value='Mail Sent'>Mail Sent</option>
                          <option value='Communication Undeliverable'>
                            Communication Undeliverable
                          </option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {status === REFUND_STATUSES.EXCEPTION && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={subStatus}
                          onChange={(e) => setSubStatus(e.target.value)}>
                          <option value=''>SubStatus</option>
                          <option value='Communication'>Communication</option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}

                {subStatus === 'Communication' && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={additionalInfo}
                          onChange={(e) => setAdditionalInfo(e.target.value)}>
                          <option value=''>SubStatus</option>
                          {Object.values(COMMUNICATION_EXCEPTIONS).map(
                            (status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}

                {status === REFUND_STATUSES.ESCHEATED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={subStatus}
                          onChange={(e) => setSubStatus(e.target.value)}>
                          <option value=''>SubStatus</option>
                          <option value='Dormant Pending Escheatment'>
                            Dormant Pending Escheatment
                          </option>
                          <option value='Escheatment Email Sent'>
                            Escheatment Email Sent
                          </option>
                          <option value='Escheatment Physical Mail Sent'>
                            Escheatment Physical Mail Sent
                          </option>
                          <option value='Escheatment Certified Mail Sent'>
                            Escheatment Certified Mail Sent
                          </option>
                          <option value='Escheatment NUAPA Client Review'>
                            Escheatment NUAPA Client Review
                          </option>
                          <option value='Escheated to State'>
                            Escheated to State
                          </option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {(status === REFUND_STATUSES.COMPLETED ||
                  status === REFUND_STATUSES.REQUESTED) && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={paymentMethod}
                          onChange={updatePaymentMethod}>
                          <option value=''>Payment Type</option>
                          {paymentMethods.map((method) => (
                            <option value={method.value}>{method.text}</option>
                          ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}

                {status === REFUND_STATUSES.REQUESTED && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          value={transactionStatus}
                          onChange={(e) =>
                            setTransactionStatus(e.target.value)
                          }>
                          <option value=''>Transaction Status</option>
                          {transactionStatuses.map((method) => (
                            <option value={method}>{method}</option>
                          ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {role === 'admin' && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          className='full-width-elem'
                          id='status'
                          onChange={onFacilitySelect}>
                          <option value='' style={{ fontWeight: 'bold' }}>
                            Facilities
                          </option>
                          {facilities.map((item) => {
                            return (
                              <>
                                <option
                                  value={item.id}
                                  className='parent'
                                  style={{ fontWeight: 'bold' }}
                                  key={item.id}>
                                  {item.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}
                {(posthog.isFeatureEnabled(BUSINESS_UNITS_FEATURE_FLAG) ||
                  role === 'admin') && (
                  <div className='t-header-left'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={(e) =>
                            setSelectedBusinessUnit(e.target.value)
                          }>
                          <option value=''>Business Unit</option>
                          {filteredBusinessUnits?.map((businessUnit) => (
                            <option value={businessUnit.id}>
                              {businessUnit.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                )}

                {/* <div
                  className='t-header-right'
                  style={{
                    maxWidth: '400px',
                  }}>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='status'
                        onChange={onMethodChange}
                        value={changeMethodTo}>
                        <option value=''>Change Method To</option>
                        {changeMethodOptions.map((method) => (
                          <option value={method.value}>{method.label}</option>
                        ))}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div> */}

                <div
                  className='t-header-right'
                  style={{
                    maxWidth: '200px',
                  }}>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      {(role === 'admin' ||
                        checkPermission(
                          'transaction_page',
                          'refund_information',
                          'download'
                        )) && (
                        <Button
                          className='full-width-elem'
                          style={{
                            height: '40px',
                          }}
                          onClick={() => {
                            downloadFile();
                            toast.success('Downloading report', {
                              position: 'bottom-right',
                              autoClose: 5000,
                            });
                          }}
                          color='primary'
                          // onClick={downloadCsv}
                          // disabled={loading}
                        >
                          Download
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        value={quickFilter}
                        id='status'
                        className='full-width-elem'
                        onChange={onQuickFilterChange}>
                        <option value=''>Quick Filter</option>
                        <option value='All Returned'>All Returned</option>
                        <option value='All Voided'>All Voided</option>
                        {role === 'admin' && (
                          <option value='Flagged Records'>
                            Flagged Records
                          </option>
                        )}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
              </div>
              <div>
                <div className='t-header-right'>
                  {selectedPayments.length ? (
                    <h4 style={{ margin: '-20px 0 0 0' }}>
                      Selected - <b>{selectedPayments.length}</b> ( $
                      {parseFloat(
                        selectedPayments.reduce(
                          (prev, cur) => prev + cur.refund_amount,
                          0
                        )
                      ).toFixed(2)}
                      )
                    </h4>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow style={{ cursor: 'pointer', userSelect: 'none' }}>
                    {/* <TableCell width='5%'>
                      <Checkbox
                        checked={isAllChecked()}
                        disabled={!payments.length}
                        onChange={onAllCheck}
                      />
                    </TableCell> */}
                    <TableCell onClick={() => sortByColumn('created_at')}>
                      Date Processed {sortArrow('created_at')}
                    </TableCell>
                    <TableCell onClick={() => sortByColumn('status_updatedat')}>
                      Last Updated {sortArrow('status_updatedat')}
                    </TableCell>
                    <TableCell onClick={() => sortByColumn('last_name')}>
                      Customer Information {sortArrow('last_name')}
                    </TableCell>
                    {role === 'admin' && (
                      <TableCell onClick={() => sortByColumn('facility_name')}>
                        Facility{sortArrow('facility_name')}
                      </TableCell>
                    )}
                    <TableCell onClick={() => sortByColumn('refund_amount')}>
                      Amount{sortArrow('refund_amount')}
                    </TableCell>
                    <TableCell
                      onClick={() => sortByColumn('payee_account_number')}>
                      Customer ID / CPD ID
                      {sortArrow('payee_account_number')}
                    </TableCell>
                    {(role === 'admin' ||
                      posthog.isFeatureEnabled(
                        BUSINESS_UNITS_FEATURE_FLAG
                      )) && (
                      <TableCell
                        onClick={() => sortByColumn('business_unit_name')}>
                        Business Unit Name/ Identifier
                        {sortArrow('business_unit_name')}
                      </TableCell>
                    )}
                    <TableCell>Payment Type / Status</TableCell>
                    <TableCell onClick={() => sortByColumn('refund_status')}>
                      Refund Status / Sub-Status
                      {sortArrow('refund_status')}
                    </TableCell>
                    <TableCell
                    // onClick={() => sortByColumn('refund_status')}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                {payments && payments.length > 0 ? (
                  <TableBody>
                    {payments.map((obj, index) => (
                      <TableRow
                        className='txtalign'
                        style={{
                          backgroundColor:
                            role === 'admin' && obj.is_deleted
                              ? '#FFFACD'
                              : (obj.is_error || obj.transaction_is_error) &&
                                role === 'admin'
                              ? '#eaa4a4'
                              : '',
                        }}
                        key={index}>
                        {/* <TableCell>
                          {!checkIfRefundCanBeUpdated(obj) ? (
                            <Checkbox
                              onChange={() => onCheck(obj.id)}
                              checked={obj.isChecked}
                            />
                          ) : (
                            ''
                          )}
                        </TableCell> */}
                        <TableCell>
                          {utcToLocal(obj.created_at, 'MM/DD/YYYY')}
                        </TableCell>
                        <TableCell>
                          {obj.status_updatedat
                            ? utcToLocal(obj.status_updatedat, 'MM/DD/YYYY')
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {obj.first_name} {obj.last_name}
                          <a
                            title='Edit Customer Information'
                            onClick={() => {
                              setCurrentInfo(obj);
                              setOriginalInfo(obj);
                              setModalErr('');
                            }}>
                            <EditRounded
                              style={{ cursor: 'pointer', fontSize: '18px' }}
                              fontSize='inherit'
                            />
                          </a>
                          {obj.email ? (
                            <span style={{ display: 'flex' }}>
                              <p style={{ fontSize: '12px' }}>({obj.email})</p>{' '}
                            </span>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        {role === 'admin' && (
                          <TableCell>{obj.facility_name}</TableCell>
                        )}
                        <TableCell>
                          $
                          {parseFloat(obj && obj.refund_amount).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}{' '}
                        </TableCell>
                        <TableCell>
                          {obj.payee_account_number || 'N/A'} <br />
                          {obj.id || 'N/A'}
                        </TableCell>
                        {(role === 'admin' ||
                          posthog.isFeatureEnabled(
                            BUSINESS_UNITS_FEATURE_FLAG
                          )) && (
                          <TableCell>
                            {obj.business_unit_name || 'N/A'} <br />
                            {obj.business_unit_identifier || 'N/A'}
                          </TableCell>
                        )}
                        <TableCell>
                          {obj.refund_method ? obj.refund_method_string : 'N/A'}
                          <br />
                          <span
                            style={{
                              fontSize: '12px',
                              color:
                                obj.transaction_status === 'ACH-returned'
                                  ? '#ff0000'
                                  : '',
                            }}>
                            {obj.transaction_status || ' '}
                          </span>
                          <br />
                          <span style={{ fontSize: '12px' }}>
                            {obj?.additional_info
                              ? `(${obj?.additional_info})`
                              : ''}
                          </span>
                        </TableCell>
                        <TableCell>
                          {/* {obj && obj.payment_status} */}
                          {obj && obj.refund_status && (
                            <span>
                              <a
                                style={{
                                  color:
                                    obj.refund_status ===
                                    REFUND_STATUSES.COMPLETED
                                      ? ''
                                      : '#000000',

                                  cursor: 'auto',
                                }}>
                                {obj.refund_status ? obj.refund_status : 'N/A'}
                              </a>
                              <br />
                              <span style={{ fontSize: '12px' }}>
                                {(obj && obj.refund_sub_status) || 'N/A'}
                              </span>
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              gap: '2px',
                              alignItems: 'center',
                            }}>
                            <HistoryRounded
                              style={{ color: '#3AB795' }}
                              title='Status History'
                              onClick={() => setSelectedRefund(obj.id)}
                            />

                            <LongMenu
                              type={role}
                              handleCopyToClipboard={() =>
                                copyLinkToClipBoard(obj.id)
                              }
                              handleRefundHistory={() => {
                                setSelectedForTrackChangesId(obj.id);
                                getInfoHistory(obj.id, obj);
                              }}
                              statusAction={(path) =>
                                statusAction(
                                  `/${path}${
                                    role === 'admin' ? '_admin' : ''
                                  }/`,
                                  obj.id
                                )
                              }
                              toggleDelete={() => toggleDelete(obj.id)}
                              refund={obj}
                              sendCheck={() => {
                                setCurrentCheckRefund(obj);
                              }}
                            />
                          </div>
                        </TableCell>
                        {/* <TableCell width="15%"> <a style={{ cursor: "pointer" }} id={obj && obj.id} onClick={(event) => deleteTransaction(event, obj && obj.facility_id, obj && obj.refund_id)} >
                          <span className="material-icons">delete</span>  </a>

                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No Transaction Data Found</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <Modal
        open={selectedRefund !== null}
        onClose={() => setSelectedRefund(null)}
        scroll='body'
        aria-labelledby='form-dialog-title'
        className='otp-dialog'
        style={{ overflow: 'visible', width: '80vw' }}>
        <Box
          sx={{ ...modalStyle, maxWidth: '80vw' }}
          style={{ maxHeight: '90vh', width: '80vw', overflow: 'visible' }}>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>Status History</h4>
          </CardHeader>
          <div style={{ width: '100%', padding: '30px', overflow: 'scroll' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Updated Date
                    <br /> / Time
                  </TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Payment/ Refund Status</TableCell>
                  {role === 'admin' && <TableCell>External_id</TableCell>}
                  <TableCell>Updated By</TableCell>
                  <TableCell>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusHistory &&
                  statusHistory.map((obj, index) => (
                    <TableRow className='txtalign' key={index}>
                      <TableCell>
                        {utcToLocal(obj.created_at, 'MM-DD-YY')}
                        <br />
                        {utcToLocal(obj.created_at, 'hh:mm A')}
                      </TableCell>

                      <TableCell>
                        <div style={{ textAlign: 'center' }}>
                          {getRefundMethodString(obj.refund_method) ?? 'N/A'}{' '}
                          <br />
                          {obj.additional_info
                            ? `(${obj.additional_info})`
                            : ''}{' '}
                        </div>
                      </TableCell>
                      <TableCell>{obj.refund_status}</TableCell>
                      {role === 'admin' && (
                        <TableCell>{obj.external_id}</TableCell>
                      )}
                      <TableCell>{obj.updated_by}</TableCell>
                      <TableCell>{obj.comment}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </Box>
      </Modal>

      <Modal
        open={currentCheckRefund ? true : false}
        onClose={() => setCurrentCheckRefund(null)}>
        <Box
          sx={modalStyle}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>Process Payment</h4>
          </CardHeader>
          <CardBody>
            {currentCheckRefund && (
              <div>
                <p style={{ marginBottom: '7px', fontWeight: 'bold' }}>
                  Address on file:{' '}
                  <a
                    onClick={() => {
                      setCurrentInfo(currentCheckRefund);
                      setOriginalInfo(currentCheckRefund);
                      setModalErr('');
                    }}>
                    <EditRounded
                      style={{ cursor: 'pointer' }}
                      fontSize='small'
                    />
                  </a>
                </p>
                <p style={{ marginBottom: '7px' }} className='page-desc'>
                  {currentCheckRefund.refund_to_fullname}
                </p>
                <p style={{ marginBottom: '7px' }} className='page-desc'>
                  {currentCheckRefund.refund_to_address_line1},{' '}
                  {currentCheckRefund.refund_to_address_line2}
                </p>
                <p style={{ marginBottom: '7px' }} className='page-desc'>
                  {currentCheckRefund.refund_to_city},{' '}
                  {currentCheckRefund.refund_to_state},{' '}
                  {currentCheckRefund.refund_to_zip}{' '}
                </p>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}></div>
              <Button
                onClick={() => processPayment(currentCheckRefund.id)}
                variant='contained'
                color='primary'>
                Issue check
              </Button>
            </div>
          </CardBody>
        </Box>
      </Modal>
      <Modal
        open={currentInfo ? true : false}
        onClose={() => {
          setCurrentInfo(null);
          setOriginalInfo(null);
        }}>
        <form onSubmit={(e) => updateInfo(e, currentInfo, originalInfo)}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Update Refund Info </h4>
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              {modalErr && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  {modalErr}
                </p>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    name='email'
                    labelText='Email'
                    value={currentInfo ? currentInfo['email'] : ''}
                    autoComplete='off'
                    onChange={onInputChange}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='first_name'
                    labelText='First Name'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['first_name'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='last_name'
                    labelText='Last Name'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['last_name'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_address_line1'
                    labelText='Address Line 1'
                    onChange={onInputChange}
                    value={
                      currentInfo ? currentInfo['refund_to_address_line1'] : ''
                    }
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_address_line2'
                    labelText='Address Line 2'
                    onChange={onInputChange}
                    value={
                      currentInfo ? currentInfo['refund_to_address_line2'] : ''
                    }
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_city'
                    labelText='City'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['refund_to_city'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_state'
                    labelText='State'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['refund_to_state'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='refund_to_zip'
                    labelText='Zip Code'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['refund_to_zip'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='comment'
                    labelText='Comment'
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['comment'] : ''}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',
                  }}>
                  <input
                    type='checkbox'
                    name='resendEmail'
                    labelText=''
                    onChange={onInputChange}
                    value={currentInfo ? currentInfo['resendEmail'] : ''}
                  />
                  <label>Resend Email</label>
                </div>
                <Button variant='contained' color='primary' type='submit'>
                  Update
                </Button>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>
      <Modal
        open={selectedForTrackChangesId ? true : false}
        onClose={() => {
          setSelectedForTrackChangesId(null);
        }}>
        <Box
          sx={modalStyle}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>Customer Information Update History</h4>
          </CardHeader>
          <CardBody style={{ marginTop: '-40px', overflowY: 'scroll' }}>
            {infoHistory.map((info, index) => {
              return (
                <GridContainer>
                  <GridItem
                    style={{ marginTop: '20px' }}
                    xs={12}
                    sm={12}
                    md={12}>
                    {index === 0 && (
                      <h5
                        style={{
                          background: '#3AB795',
                          padding: '5px',
                          borderRadius: '5px',
                          display: 'inline',
                          color: '#fff',
                          width: 'fit-content',
                          fontSize: '14px',
                          margin: 0,
                        }}>
                        Latest
                      </h5>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '10px 0',
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        borderRadius: '5px',
                        padding: '10px',
                      }}>
                      <p style={{ fontSize: '14px' }}>
                        <b>Updated By :</b> {info.updated_by}
                      </p>
                      <p style={{ fontSize: '12px', marginTop: '-5px' }}>
                        <b>Comment : </b> {info.comment}
                      </p>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>First Name</label>

                      <TextField
                        variant='outlined'
                        className={getClass(info, index, 'first_name')}
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={1}
                        maxRows={2}
                        value={info.first_name}
                        onChange={() => null}
                        name='cc'
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>Last Name</label>
                      <TextField
                        variant='outlined'
                        id='component-error'
                        className={getClass(info, index, 'last_name')}
                        // color='danger'
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={1}
                        maxRows={2}
                        value={info.last_name}
                        onChange={() => null}
                        name='cc'
                      />
                    </div>
                  </GridItem>
                  <GridItem
                    style={{ marginTop: '20px' }}
                    xs={12}
                    sm={12}
                    md={12}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>Email</label>
                      <TextField
                        variant='outlined'
                        className={getClass(info, index, 'email')}
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={1}
                        maxRows={1}
                        value={info.email}
                        onChange={() => null}
                        name='cc'
                      />
                    </div>
                  </GridItem>

                  <GridItem
                    style={{ marginTop: '20px' }}
                    xs={12}
                    sm={12}
                    md={12}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>Address</label>
                      <TextField
                        variant='outlined'
                        className={getClass(info, index, 'address')}
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={4}
                        maxRows={6}
                        value={info.address}
                        onChange={() => null}
                        name='cc'
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              );
            })}
          </CardBody>
        </Box>
      </Modal>
    </GridContainer>
  );
}
